import { ToasterToast, useToast } from '@shared/hooks/useToast';
import React from 'react';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from './Toast';

function Description({ description, variant }: Pick<ToasterToast, 'description' | 'variant'>) {
  let iconPrefix = null;
  if (variant === 'success') {
    iconPrefix = <i className="bi bi-check2-circle tw-mr-2 tw-self-center" />;
  } else if (variant === 'destructive') {
    iconPrefix = <i className="bi bi-exclamation-circle tw-mr-2 tw-self-center" />;
  } else if (variant === 'warning') {
    iconPrefix = <i className="bi bi-exclamation-circle tw-mr-2 tw-self-center" />;
  }

  return (
    <ToastDescription className="tw-flex tw-gap-1">
      {iconPrefix}
      {description}
    </ToastDescription>
  );
}

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, duration = 10000, ...props }: ToasterToast) {
        return (
          <Toast key={id} duration={duration} {...props}>
            <div className="tw-flex tw-gap-1 tw-items-center tw-justify-between tw-w-full">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <Description description={description} variant={props.variant} />}
              {action}
              <ToastClose />
            </div>
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
