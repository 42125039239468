import { zodResolver } from '@hookform/resolvers/zod';
import Select from '@shared/react_components/Select';
import useOfficeLabStore from '@shared/state/office-lab-store';
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  pms: z.string().min(1, { message: 'PMS Name is required' }),
});

type FormDataShape = z.infer<typeof formSchema>;

const PMS_TO_EMAIL_MAP = {
  Compulink: 'support@compulinkadvantage.com',
  EyefinityPM: 'epmsupport@eyefinity.com',
  Maximeyes: 'sales@first-insight.com',
  'OfficeMate/ExamWRITER': 'officematesupport@eyefinity.com',
  RevolutionEHR: 'customersupport@revolutionehr.com',
};

const pmsDisplayOptions = Object.keys(PMS_TO_EMAIL_MAP).map((key) => {
  return { id: key, name: key };
});

type Props = {
  show: boolean;
  onClose: () => void;
};

export default function PMSIntegrationMailTo({ show, onClose }: Props) {
  const activeOfficeLab = useOfficeLabStore();
  const { clearErrors, control, register, handleSubmit, setValue, formState, reset, trigger, resetField } = useForm<FormDataShape>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      pms: '',
    },
    mode: 'all',
  });

  const [pms] = useWatch({
    control,
    name: ['pms'],
  });

  useEffect(() => {
    register('pms');
  }, []);

  function onSubmit(data: FormDataShape) {
    const address = PMS_TO_EMAIL_MAP[data.pms as keyof typeof PMSIntegrationMailTo];
    const ecpName = activeOfficeLab.office.name;
    const pmsName = data.pms;
    const emailContent = `mailto:${address}?cc=support@speccheckrx.com&subject=Potential%20Integration%20With%20SpecCheck&body=Hi%2C%0A%0AI%20hope%20this%20email%20finds%20you%20well.%20I%20am%20an%20eye%20care%20professional%20at%20${ecpName}%20and%20am%20a%20current%20customer%20of%20yours.%0A%0AI%20am%20reaching%20out%20to%20ask%20about%20the%20possibility%20of%20integrating%20${pmsName}%20with%20SpecCheck.%20We%20currently%20use%20SpecCheck%20and%20would%20love%20it%20if%20${pmsName}%20could%20also%20integrate%20directly%20with%20them.%0A%0AThis%20would%20help%20us%20out%20greatly.%20Thank%20you!%0A%0AWarm%20regards%2C%0A${ecpName}%0A`;
    window.open(emailContent, '_blank');
  }

  const selectedPMS = { id: pms, name: pms || 'Select a PMS' };

  return (
    <Modal
      show={show}
      onHide={() => {
        reset();
      }}
    >
      <Modal.Header>
        <h5 className="modal-title">Help us connect with your PMS partner for a SpecCheck ordering integration</h5>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="modal-body">
          <div className="mb-2">
            <label htmlFor="reportDescription" className="form-label">
              PMS
            </label>
            <Select
              selected={selectedPMS}
              options={pmsDisplayOptions}
              onSelect={(item) => {
                if (item.id === '') {
                  // We selected the default value
                  setValue('pms', '');
                  trigger('pms');
                  return;
                }
                clearErrors('pms');
                setValue('pms', item.id);
                trigger('pms');
              }}
              displayFn={(item) => item.name}
              listRowDisplayFn={(item) => {
                if (item.id === '') {
                  return <span className="text-muted">{item.name}</span>;
                }
                return item.name;
              }}
            />
            {formState.errors.pms && <div className="invalid-feedback">{formState.errors.pms.message}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={!formState.isValid}>
            Create Email For Review
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
