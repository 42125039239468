import React from 'react';
import { IAddress } from '@core/state/user-metadata/user-metadata.state';
import { cn } from '@shared/utils/styles';

const overflowClassNames = 'tw-max-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap';

interface Props {
  address?: IAddress;
}

export default function Address({ address }: Props) {
  if (!address) {
    return null;
  }

  return (
    <div className={cn('tw-text-left', overflowClassNames)}>
      <span className={overflowClassNames}>
        {address.line1}
        {address.line2 && ` ${address.line2}`}
      </span>
      <br />
      <span className={overflowClassNames}>
        {address.city}, {address.state} {address.postal_code}
      </span>
    </div>
  );
}
