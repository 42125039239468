import React from 'react';
import { NavLink } from 'react-router-dom';
import SpecCheckSymbolLogo from '@assets/icons/logo-symbol.svg';

interface Props {
  showText?: boolean;
}

export default function Logo({ showText }: Props) {
  return (
    <NavLink
      id="sc-logo"
      to="./"
      className="tw-h-full tw-flex tw-items-center tw-justify-center tw-flex-row md:tw-px-1 tw-gap-3 tw-no-underline"
    >
      <SpecCheckSymbolLogo className="tw-w-10 md:tw-w-12" />
      {showText && <span className="tw-text-xl tw-font-bold tw-text-white tw-no-underline tw-select-none">SpecCheck</span>}
    </NavLink>
  );
}
