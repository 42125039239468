import React, { MouseEventHandler } from 'react';
import MenuBurgerIcon from '@assets/icons/MenuBurger-40-White.svg';
import { SidebarDisplayMode } from '../types';
import PortalTooltip from '@shared/react_components/PortalTooltip';

interface Props {
  sidebarDisplayMode: SidebarDisplayMode;
  onMenuButtonClick: MouseEventHandler<HTMLButtonElement>;
}

export default function MenuButton({ sidebarDisplayMode, onMenuButtonClick }: Props) {
  return (
    <PortalTooltip
      tooltipContent={`Click to ${sidebarDisplayMode === 'expanded' ? 'collapse' : 'expand'} the sidebar`}
      tooltipOffsetY={44}
      tooltipOffsetX={0}
    >
      {({ ...props }) => (
        <button
          className="tw-w-8 tw-h-8 md:tw-w-10 md:tw-h-10 tw-flex tw-items-center tw-justify-center tw-p-0 tw-appearance-none tw-bg-transparent tw-rounded-lg tw-transition hover:tw-bg-primary-600 active:tw-opacity-80 tw-border-none tw-shadow-none"
          onClick={onMenuButtonClick}
          {...props}
        >
          <MenuBurgerIcon />
        </button>
      )}
    </PortalTooltip>
  );
}
