import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import Loading from './modules/shared/react_components/Loading';

type ProtectedRouteProps = {
  component: React.ComponentType<object>;
};
export default function ProtectedRoute({ component, ...args }: ProtectedRouteProps): JSX.Element {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => {
      return (
        <div className="tw-flex tw-grow tw-items-center tw-justify-center tw-h-full pt-3 bg-accent">
          <Loading />
        </div>
      );
    },
    ...args,
  });

  return <Component />;
}
