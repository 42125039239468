import React, { useEffect } from 'react';
import { EVENTS } from '@shared/constants/events';
import posthog from 'posthog-js';
import { useRouteError } from 'react-router-dom';

interface Props {
  title: string;
  description?: React.ReactNode;
  hideRefreshButton?: boolean;
  source?: string;
  event?: string;
}

export default function ErrorScreen({ title, description, hideRefreshButton, source, event }: Props) {
  const error = useRouteError();

  useEffect(() => {
    if (!source) return;

    posthog.capture(event ?? EVENTS.GENERIC_ERROR, {
      source,
      error: (error as Error)?.message,
    });
  }, []);

  return (
    <div className="tw-w-full tw-h-full">
      <div className="tw-flex tw-justify-center tw-items-center tw-min-h-screen sc-static-page">
        <div className="tw-text-center sc-white-box sc-shadow" style={{ maxWidth: '25rem' }}>
          <img className="tw-mb-2" style={{ height: '50px', width: '100px' }} src="assets/svgs/logo_primary_color.svg" />
          <h2 className="tw-mb-2 tw-font-bold tw-text-primary">{title}</h2>
          <p>
            {description || (
              <>
                Please refresh the page and try again. If this issue persists, please contact us at{' '}
                <a href="mailto:support@speccheckrx.com">support@speccheckrx.com</a>.
              </>
            )}
          </p>

          {!hideRefreshButton && (
            <button className="btn btn-primary" onClick={() => window.location.reload()}>
              Refresh Page
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
