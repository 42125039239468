import React, { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';
import { Screen } from './types';

const SCREEN_TRANSITION_DISTANCE = '0.5rem';

const initialScreenVariants = {
  hidden: { opacity: 0, x: `-${SCREEN_TRANSITION_DISTANCE}` },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: `-${SCREEN_TRANSITION_DISTANCE}` },
};

const resourceScreenVariants = {
  hidden: { opacity: 0, x: SCREEN_TRANSITION_DISTANCE },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: SCREEN_TRANSITION_DISTANCE },
};

interface Props {
  screen: Screen;
  type: 'initial' | 'resourceList';
}

export default function AnimatedScreen({ screen, type, children }: PropsWithChildren<Props>) {
  return (
    <motion.div
      custom={screen}
      variants={type === 'initial' ? initialScreenVariants : resourceScreenVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      transition={{
        type: 'tween',
        duration: 0.15,
        ease: [0.2, 0, 0.38, 0.9],
      }}
    >
      {children}
    </motion.div>
  );
}
