import React, { ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { cn } from '@shared/utils/styles';

interface TooltipDisplayContext {
  top?: number;
  left?: number;
  opacity: number;
}

interface Props {
  tooltipContent: ReactNode;
  tooltipOffsetY?: number;
  tooltipOffsetX?: number;
  tooltipClassName?: string;
  disableTooltip?: boolean;
  children: (props: {
    onMouseOver: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    onMouseLeave: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  }) => ReactNode;
}

export default function PortalTooltip({
  tooltipContent,
  tooltipOffsetY = 48,
  tooltipOffsetX = 0,
  tooltipClassName,
  disableTooltip,
  children,
}: Props) {
  const [tooltipContext, setTooltipContext] = useState<TooltipDisplayContext>({
    top: undefined,
    left: undefined,
    opacity: 0,
  });

  const handleMouseEvent = (ev: React.MouseEvent<HTMLButtonElement>, type: 'enter' | 'leave') => {
    if (disableTooltip) {
      setTooltipContext((ctx) => ({
        ...ctx,
        opacity: 0,
      }));

      return;
    }

    setTooltipContext({
      top: ev.currentTarget.offsetTop + tooltipOffsetY,
      left: ev.currentTarget.offsetLeft + tooltipOffsetX,
      opacity: type === 'enter' ? 1 : 0,
    });
  };

  return (
    <>
      {children({
        onMouseOver: (ev: React.MouseEvent<HTMLButtonElement>) => handleMouseEvent(ev, 'enter'),
        onMouseLeave: (ev: React.MouseEvent<HTMLButtonElement>) => handleMouseEvent(ev, 'leave'),
      })}

      {createPortal(
        <div
          className={cn(
            'tw-fixed tw-z-[1001] tw-h-8 tw-rounded-lg tw-bg-neutral-800 tw-text-white tw-px-3 tw-py-1 tw-flex tw-items-center tw-justify-center tw-flex-col tw-pointer-events-none tw-overflow-hidden',
            tooltipClassName
          )}
          style={{
            top: tooltipContext.top,
            left: tooltipContext.left,
            opacity: tooltipContext.opacity,
            transition: '0.2s ease-in-out',
          }}
        >
          <p className="tw-text-sm tw-p-0 tw-m-0">{tooltipContent}</p>
        </div>,
        document.body
      )}
    </>
  );
}
