import { EVENTS } from '@shared/constants/events';
import useIntercom from '@shared/hooks/useIntercom';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import { Button } from '@shared/react_components/Button';
import { AnimatePresence, motion } from 'framer-motion';
import posthog from 'posthog-js';
import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { Userpilot } from 'userpilot';
import EcpLinks from './EcpLinks';
import LabLinks from './LabLinks';
import Separator from './Separator';
import SidebarLinkGroup from './SidebarLinkGroup';

import CreateOrderIcon from '@assets/icons/CreateOrder-24-CurrentColor.svg';
import InfoIcon from '@assets/icons/InformationSquare-24-CurrentColor.svg';
import ChatIcon from '@assets/icons/MessagesChat-24-CurrentColor.svg';
import PortalTooltip from '@shared/react_components/PortalTooltip';

interface Props {
  displayMode: 'expanded' | 'collapsed';
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  isMobile?: boolean;
}

export default function Sidebar({ displayMode, setSidebarOpen, isMobile }: Props) {
  const navigate = useNavigate();
  const { isAvailable } = useIntercom();

  const { data: user } = usePopulateUser();
  invariant(user, 'User cannot be null within SideNav');

  const isLabUser = user.user_type === 'labUser';
  const isEcpUser = user.user_type === 'ecpUser';

  const links = isLabUser ? LabLinks() : EcpLinks();

  return (
    <aside
      style={{ width: isMobile ? '100%' : displayMode === 'expanded' ? '13.5rem' : '5rem', transition: '0.2s ease-in-out' }}
      className="tw-h-full tw-p-4 tw-bg-white !tw-border-y-0 !tw-border-l-0 !tw-border-r !tw-border-solid !tw-border-r-neutral-200 tw-flex tw-items-center tw-justify-between tw-flex-col tw-gap-4 tw-transition tw-overflow-y-auto tw-overflow-x-hidden print:tw-hidden"
    >
      <div className="tw-w-full tw-flex tw-items-center tw-justify-start tw-flex-col tw-gap-4">
        <SidebarLinkGroup groupId="top" links={links} displayMode={displayMode} setSidebarOpen={setSidebarOpen} />

        {isEcpUser && (
          <>
            <Separator />

            <PortalTooltip tooltipContent="Create order" tooltipOffsetY={8} tooltipOffsetX={72} disableTooltip={displayMode === 'expanded'}>
              {({ ...props }) => (
                <Button
                  onClick={() => {
                    navigate('./create-order');
                    setSidebarOpen(false);
                  }}
                  className="tw-w-full !tw-h-12 tw-gap-2 tw-shadow-none tw-relative tw-flex !tw-items-center !tw-justify-start tw-flex-row tw-will-change-auto"
                  style={{
                    paddingLeft: displayMode === 'expanded' ? '0.9375rem' : '0.75rem',
                    transition: '0.2s ease-in-out',
                  }}
                  {...props}
                >
                  <div className="tw-h-6 tw-w-6 tw-flex tw-items-center tw-justify-center">
                    <CreateOrderIcon />
                  </div>
                  <AnimatePresence>
                    {displayMode === 'expanded' && (
                      <motion.div
                        variants={{
                          collapsed: { opacity: 0 },
                          expanded: { opacity: 1 },
                        }}
                        initial="collapsed"
                        animate="expanded"
                        exit="collapsed"
                        transition={{ duration: 0.2 }}
                        className="tw-absolute tw-left-12 tw-flex tw-items-center tw-justify-start tw-flex-row tw-gap-2"
                      >
                        <span className="tw-text-primary tw-text-base">Create Order</span>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Button>
              )}
            </PortalTooltip>
          </>
        )}
      </div>

      <div className="tw-w-full tw-flex tw-items-center tw-justify-start tw-flex-col">
        <Separator className="tw-mb-4" />
        <SidebarLinkGroup
          groupId="bottom"
          links={[
            ...(isEcpUser
              ? [
                  {
                    label: 'Lab Live Chat',
                    icon: <ChatIcon />,
                    onClick: () => {
                      if (isAvailable) {
                        window.Intercom('show');

                        posthog.capture(EVENTS.INTERCOM_MODAL_OPENED, {
                          source: 'side-bar',
                        });
                      }
                    },
                  },
                ]
              : []),
            {
              label: 'Help',
              icon: <InfoIcon />,
              onClick: () => {
                Userpilot.trigger('resource_center:7Af0tTBnTM');
              },
            },
          ]}
          displayMode={displayMode}
          setSidebarOpen={setSidebarOpen}
        />
      </div>
    </aside>
  );
}
