import React, { Dispatch, SetStateAction, useState } from 'react';
import { Popover, PopoverContent } from '@shared/react_components/Popover';

import { Resource, Screen } from './types';
import TriggerButton from './TriggerButton';

interface ChildrenParams {
  screen: Screen;
  setScreen: Dispatch<SetStateAction<Screen>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  currentResource: Resource;
  children: (params: ChildrenParams) => JSX.Element;
}

export default function ResourceSwitcher({ currentResource, children }: Props) {
  const [open, setOpen] = useState(false);
  const [screen, setScreen] = useState<Screen>('initial');

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open);

        if (screen !== 'initial') {
          setScreen('initial');
        }
      }}
    >
      <TriggerButton currentResource={currentResource} />
      <PopoverContent
        align="center"
        sideOffset={0}
        onOpenAutoFocus={(ev) => {
          // Prevent focus from attaching to NextButton on open
          ev.preventDefault();
        }}
        className="tw-max-w-[16rem] !tw-p-2 tw-overflow-hidden"
      >
        {children({ screen, setScreen, open, setOpen })}
      </PopoverContent>
    </Popover>
  );
}
