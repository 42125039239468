export const allFrameMaterialEdgeValues = new Map<string, string>([
  ['METL, HB', 'Metal'],
  ['ZYLO, HB', 'Zyl'],
  ['METL, GD', 'Grooved Rimless'],
  ['METL, D4', 'Drilled Rimless'],
  ['METL, DNG', 'Drilled & Grooved Rimless'],
  ['ZYLO, SHLF', 'Step/Shelf Bevel'],
  ['METL, IND', 'Industrial/Safety'],
  ['METL, INDG', 'Grooved Industrial/Safety'],
  ['ZYLO, WRAP', 'High Wrap'],
]);

export const FOG_LAB_ID = 'lab_v1_97ac267759d88607b8128091e4373e95';

// Display messages
export const UNEXPECTED_API_ERROR_MSG =
  'Something unexpected occurred while attempting to process your request. Please try again. If this issue persists, please contact us at support@speccheckrx.com';
