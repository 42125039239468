export function getUrlWithHostAndProtocol() {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;

  return `${protocol}//${hostname}`;
}

export function isLocalhost(url: string) {
  return url.match(/^https?:\/\/127\.0\.0\.1$/) || url.match(/^https?:\/\/localhost$/);
}
