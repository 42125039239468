import React from 'react';
import { DropdownMenuItem } from './DropdownMenu';

import CheckPurpleIcon from '@assets/icons/Check-16-Purple.svg';

interface Props {
  icon?: React.ReactNode;
  label: string;
  onClick?: () => void;
  isActive?: boolean;
}

export default function DropdownItem({ icon, label, onClick, isActive }: Props) {
  return (
    <DropdownMenuItem className="tw-h-10 tw-min-w-[12.5rem] tw-justify-between tw-gap-2" onClick={isActive ? undefined : onClick}>
      <div className="tw-flex tw-items-center tw-justify-center tw-flex-row tw-gap-2">
        {icon}
        <span className="tw-text-base tw-font-normal">{label}</span>
      </div>
      {isActive && <CheckPurpleIcon />}
    </DropdownMenuItem>
  );
}
