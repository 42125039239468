import posthog from 'posthog-js';
import { Resource } from './types';
import { EVENTS } from '@shared/constants/events';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import invariant from 'tiny-invariant';
import useOfficeLabStore from '@shared/state/office-lab-store';

export default function useNavigateToResource() {
  const { data: user } = usePopulateUser();
  invariant(user, 'User cannot be null in lab dropdown');

  const isEcpUser = user.user_type === 'ecpUser';

  const currentOfficeLabStore = useOfficeLabStore();

  return function navigateToResource(resource: Resource) {
    if (resource.type === 'lab') {
      posthog.capture(EVENTS.LAB_CHANGED);

      if (isEcpUser) {
        window.open(window.origin + `/ecp/${currentOfficeLabStore.office.id}/lab/${resource.id}`, '_self');
      } else {
        window.open(window.origin + `/lab/${resource.id}`, '_self');
      }
    } else {
      posthog.capture(EVENTS.OFFICE_CHANGED);

      if (isEcpUser) {
        window.open(window.origin + `/ecp/${resource.id}/lab/${resource.metadata?.office_lab_id}`, '_self');
      }
    }
  };
}
