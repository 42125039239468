import { IUserMetadata } from '@shared/models/user-metadata.model';

const AllPermisions = [
  'create:payment',
  'delete:payment',
  'read:quickbooks',
  'lab:read:report',
  'lab:read:sales_report',
  'lab:read:sales_report_admin',
  'update:lab',
  'update:payment',
  'update:quickbooks',
  'lab:read:production_report',
] as const;

export type PossiblePermissions = (typeof AllPermisions)[number];

export function doesUserHaveRequiredPermissions(
  user: IUserMetadata,
  requiredPermissions: PossiblePermissions[],
  matchAllPermissions: boolean = true
) {
  if (requiredPermissions.length > 0) {
    if (matchAllPermissions) {
      return requiredPermissions.every((permission) => user?.permissions.includes(permission));
    } else {
      return requiredPermissions.some((permission) => user?.permissions.includes(permission));
    }
  } else {
    return true;
  }
}
