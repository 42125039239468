import { autoUpdate, flip, useFloating } from '@floating-ui/react';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface BaseItem {
  id: string | null;
  name: string;
}

type Props<Item> = {
  disabled?: boolean;
  displayFn?: (item: Item) => string | React.ReactNode;
  listRowDisplayFn?: (item: Item) => string | React.ReactNode;
  selected: any;
  onSelect: (choice: Item) => void;
  options: readonly Item[];
  buttonAriaLabel?: string;
  buttonClassName?: string;
  optionClassNames?: string;
};
export default function Select<ItemArg extends BaseItem>({
  buttonClassName,
  listRowDisplayFn,
  displayFn,
  onSelect,
  selected,
  options,
  buttonAriaLabel = '',
  disabled = false,
  optionClassNames = '',
}: Props<ItemArg>) {
  const [widthOfOptionsMenu, setWidthOfOptionsMenu] = useState(0);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  useEffect(() => {
    let observer: ResizeObserver;
    if (refs.reference.current) {
      // Spin up a resize observer so that changes in the referenced's width
      // is reflected in the floating element as well.
      observer = new ResizeObserver((entries) => {
        setWidthOfOptionsMenu(entries[0].target.clientWidth);
      });
      // @ts-expect-error Ref types are a mess.
      observer.observe(refs.reference.current);
    }

    return () => {
      // @ts-expect-error Ref types are a mess.
      refs.reference.current && observer.unobserve(refs.reference.current);
    };
  }, [refs.reference]);

  return (
    <div className="position-relative">
      <Listbox disabled={disabled} value={selected} onChange={onSelect} by="id">
        {/* Until we have our own css, opting to use bootstrap utility classes with some overrides to match visually */}
        <Listbox.Button aria-label={buttonAriaLabel} ref={refs.setReference} className={`form-select d-flex ${buttonClassName}`}>
          {displayFn ? displayFn(selected) : selected}
        </Listbox.Button>
        {createPortal(
          <Listbox.Options
            className="d-flex flex-column border rounded list-unstyled position-absolute bg-white shadow dropdown-container p-1"
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              width: widthOfOptionsMenu,
              zIndex: 1200,
            }}
          >
            {options.map((option) => (
              <Listbox.Option key={option.id ?? option.name} value={option} as={Fragment}>
                {({ active }) => (
                  <li
                    data-active={active}
                    className={clsx('pt-2 ps-3 pe-3 pb-2 dropdown-selection', {
                      [optionClassNames]: optionClassNames ?? false,
                      'dropdown-selection--hover': active,
                    })}
                  >
                    {listRowDisplayFn ? listRowDisplayFn(option) : option.name}
                  </li>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>,
          document.body
        )}
      </Listbox>
    </div>
  );
}
