import React from 'react';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import { IUserMetadata } from '@shared/models/user-metadata.model';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@shared/react_components/DropdownMenu';

import BuildingIcon from '@assets/icons/Building-24-CurrentColor.svg';
import LabIcon from '@assets/icons/Lab-24-CurrentColor.svg';
import ChevronDownIcon from '@assets/icons/ChevronDown-24-White.svg';
import DropdownLinkItem from '@shared/react_components/DropdownLinkItem';

function generateLabViewUrl() {
  return window.location.origin;
}

function generateEcpViewUrl(populatedUser: IUserMetadata) {
  return window.location.origin + `/ecp/${populatedUser.office_metadata[0].id}/lab/${populatedUser.office_metadata[0].lab_metadata[0].id}`;
}

export default function ContextSwitcher() {
  const { data: populatedUser } = usePopulateUser();

  if (!populatedUser) {
    return null;
  }

  const isEcpView = populatedUser.user_type === 'ecpUser';
  const isLabView = populatedUser.user_type === 'labUser';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button
          id="sc-context-switcher"
          className={
            'tw-pl-2 tw-pr-1 tw-flex tw-items-center tw-justify-center tw-flex-row tw-appearance-none tw-shadow-none tw-border-none tw-rounded-lg tw-transition hover:tw-bg-primary-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-600'
          }
        >
          <span className="tw-text-base md:tw-text-xl tw-font-bold tw-text-white tw-no-underline tw-select-none">
            {isLabView ? 'Labs' : 'SpecCheck'}
          </span>
          <ChevronDownIcon />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" alignOffset={-24}>
        <DropdownLinkItem href={generateEcpViewUrl(populatedUser)} icon={<BuildingIcon />} label="ECP View" isActive={isEcpView} />
        <DropdownLinkItem href={generateLabViewUrl()} icon={<LabIcon />} label="Lab View" isActive={isLabView} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
