import React from 'react';
import useOfficeLabStore from '@shared/state/office-lab-store';
import { Link } from './types';

import HomeIcon from '@assets/icons/Home-24-CurrentColor.svg';
import DraftIcon from '@assets/icons/Draft-24-CurrentColor.svg';
import DollarIcon from '@assets/icons/Dollar-24-CurrentColor.svg';
import GiftIcon from '@assets/icons/Gift-24-CurrentColor.svg';
import DollarPaymentIcon from '@assets/icons/DollarPayment-24-CurrentColor.svg';
import ReportChartIcon from '@assets/icons/ReportChart-24-CurrentColor.svg';
import SettingIcon from '@assets/icons/Setting-24-CurrentColor.svg';

const routeMap = {
  LAB_PAY: 'lab-pay',
  DRAFT_ORDERS: 'draft-orders',
  REPORTS: 'reports',
  ORDERS: 'orders',
  REWARDS: 'rewards',
  POS: 'point-of-sale',
  SETTINGS: 'settings',
};

const prefix = '^/ecp/[^/]+/lab/[^/]+/';
const matchAny = '(/.*)?$';

const links: Required<Omit<Link, 'onClick'>>[] = [
  {
    href: routeMap.ORDERS,
    label: 'Home',
    icon: <HomeIcon />,
    matchPattern: new RegExp(prefix + routeMap.ORDERS + matchAny),
  },
  {
    href: routeMap.DRAFT_ORDERS,
    label: 'Drafts',
    icon: <DraftIcon />,
    matchPattern: new RegExp(prefix + routeMap.DRAFT_ORDERS + matchAny),
  },
  {
    href: routeMap.LAB_PAY,
    label: 'Lab Pay',
    icon: <DollarIcon />,
    matchPattern: new RegExp(prefix + routeMap.LAB_PAY + matchAny),
  },
  {
    href: routeMap.REWARDS,
    label: 'Rewards',
    icon: <GiftIcon />,
    matchPattern: new RegExp(prefix + routeMap.REWARDS + matchAny),
  },
  {
    href: routeMap.POS,
    label: 'Point of Sale',
    icon: <DollarPaymentIcon />,
    matchPattern: new RegExp(prefix + routeMap.POS + matchAny),
  },
  {
    href: routeMap.REPORTS,
    label: 'Reports',
    icon: <ReportChartIcon />,
    matchPattern: new RegExp(prefix + routeMap.REPORTS + matchAny),
  },
  {
    href: routeMap.SETTINGS,
    label: 'Settings',
    icon: <SettingIcon />,
    matchPattern: new RegExp(prefix + routeMap.SETTINGS + matchAny),
  },
];

const ECPS_IN_POS_TRIAL = new Map([
  // Initial test list for Superior's ECP that will be using Point Of Sale
  ['ecp_v2_zF7iH2ulLWLjmd', true],
  ['ecp_v2_9lgmQ6h1B9E3rM', true],
  ['ecp_v2_zF7iWDTMLWLjmd', true],
  ['ecp_v2_UpPYPzjMyYxvQF', true],
  // Our SpecCheck Optometry for outside testing purposes
  ['ecp_v2_MdxQn3LjZaRbKA', true],
  // SpecCheck Optometry used in the Cypress tests
  ['ecp_v1_17202f70e903be66d6534b8ff51ed3eb', true],
]);

export default function EcpLinks() {
  const currentOfficeLab = useOfficeLabStore();

  const routeToEnabledMap = {
    [routeMap.ORDERS]: true,
    [routeMap.DRAFT_ORDERS]: true,
    [routeMap.LAB_PAY]: true,
    [routeMap.POS]: ECPS_IN_POS_TRIAL.has(currentOfficeLab.office.id!),
    [routeMap.REWARDS]: true,
    [routeMap.REPORTS]: true,
    [routeMap.SETTINGS]: true,
  };

  return links.filter((link) => routeToEnabledMap[link.href]);
}
