import usePopulateUser from '@shared/hooks/usePopulateUser';
import useUserPilotStore, { ModalID } from '@shared/state/userpilot-store';
import { getConfig } from '@shared/utils/config';
import posthog from 'posthog-js';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import ECPAppView from './ECPAppView';
import LabAppView from './LabAppView';

// For google analytics
declare let gtag: Function;
const config = getConfig();
function triggerGoogleAnalytics(url: string) {
  // Only track analytics for prod
  if (config.production) {
    gtag('config', 'G-PJ6NW5TYY8', {
      page_path: url,
    });
  }
}

export default function AppShell() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: populatedUser, error } = usePopulateUser();
  const { openModal } = useUserPilotStore();

  // Handles the use case where userType is unknown or an error happens.
  useEffect(() => {
    if (populatedUser?.user_type === 'unknown' || error) {
      navigate('/account', {
        replace: true,
      });
      return;
    }
  }, [populatedUser, error, navigate]);

  // Triggers 3rd party verification for user_type agnostic applications
  useEffect(() => {
    if (populatedUser) {
      posthog.identify(populatedUser.user_id, {
        appID: RELEASE_ID,
      });
    }
  }, [populatedUser?.user_id]);

  // Triggers 3rd party page change event logic
  useEffect(() => {
    if (!populatedUser) {
      return;
    }

    // UP needs to be reloaded on each route change
    Userpilot.reload();

    // Trigger Google analytics
    triggerGoogleAnalytics(location.pathname + location.search);

    // Trigger PostHog
    posthog.capture('$pageview');
  }, [location, populatedUser]);

  // Used to trigger opening and closing modals from UserPilot. Once there are additional use cases, this logic will need to be updated
  useEffect(() => {
    const handleOpenModalEvent = (event: OpenModalEvent) => {
      const modalID = event.detail.modalID as ModalID;
      openModal(modalID);
    };

    window.addEventListener('openModalEvent', handleOpenModalEvent);

    return () => {
      window.removeEventListener('openModalEvent', handleOpenModalEvent);
    };
  }, [openModal]);

  switch (populatedUser?.user_type) {
    case 'labUser':
      return <LabAppView />;
    case 'ecpUser':
      return <ECPAppView />;
    default:
      return null;
  }
}
