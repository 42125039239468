import React from 'react';
import ResourceSwitcher from './ResourceSwitcher';
import AnimatedScreen from './AnimatedScreen';
import InitialScreen from './InitialScreen';
import ResourceListScreen from './ResourceListScreen';
import { AnimatePresence } from 'framer-motion';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import invariant from 'tiny-invariant';
import { mapLabToResource } from './utils';
import useLabStore from '@shared/state/lab-store';

export default function LabResourceSwitcher() {
  const { data: user } = usePopulateUser();
  invariant(user, 'User cannot be null in lab dropdown');

  const { lab: currentLab } = useLabStore();

  const currentLabResource = mapLabToResource(currentLab);
  const labs = user.labs.filter((lab) => lab.lab_id !== currentLabResource.id).map(mapLabToResource);

  return (
    <ResourceSwitcher currentResource={currentLabResource}>
      {({ screen, setScreen, setOpen }) => (
        <AnimatePresence initial={false} custom={screen} mode="wait">
          {screen === 'initial' && (
            <AnimatedScreen key="initial" screen={screen} type="initial">
              <InitialScreen
                currentResource={currentLabResource}
                setScreen={setScreen}
                showLabSwitcher={labs.length > 0}
                showOfficeSwitcher={false}
              />
            </AnimatedScreen>
          )}

          {screen === 'labList' && (
            <AnimatedScreen key="labList" screen={screen} type="resourceList">
              <ResourceListScreen
                currentResource={currentLabResource}
                resourceList={[currentLabResource, ...labs]}
                setOpen={setOpen}
                setScreen={setScreen}
              />
            </AnimatedScreen>
          )}
        </AnimatePresence>
      )}
    </ResourceSwitcher>
  );
}
