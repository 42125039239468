import { create } from 'zustand';

export type ModalID = 'addAdditionalLabModal' | 'pmsIntegrationMailToModal' | 'reportIssueModal';

type State = {
  modals: Record<ModalID, boolean>;
};

type Action = {
  openModal: (id: ModalID) => void;
  closeModal: (id: ModalID) => void;
};

const useUserPilotStore = create<State & Action>((set) => ({
  modals: { addAdditionalLabModal: false, pmsIntegrationMailToModal: false, reportIssueModal: false },
  openModal: (id) =>
    set((state) => ({
      modals: { ...state.modals, [id]: true },
    })),
  closeModal: (id) =>
    set((state) => ({
      modals: { ...state.modals, [id]: false },
    })),
}));

export default useUserPilotStore;
