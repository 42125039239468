import { ILab } from '@core/state/user-metadata/user-metadata.state';
import { IOffice } from '@core/models/office.model';
import { addressFormatter } from '@shared/react_utils/addressFormatter';
import { Resource } from './types';
import { ILab as IOfficeLab } from '@core/models/lab.model';

function getLabInformation(lab: IOfficeLab): string {
  return `${lab.name} - ${lab.account_number}`;
}

export function mapLabToResource(lab: ILab): Resource {
  return {
    id: lab.lab_id,
    name: lab.lab_name,
    address: lab.address,
    type: 'lab',
  };
}

export function mapOfficeLabToResource(lab: IOfficeLab): Resource {
  return {
    id: lab.id ?? '',
    name: lab.name,
    labInformation: getLabInformation(lab),
    type: 'lab',
  };
}

export function mapOfficeToResource(office: IOffice, currentOfficeLab: IOfficeLab): Resource {
  return {
    id: office.id as string,
    name: office.name,
    address: office.address,
    type: 'office',
    labInformation: getLabInformation(currentOfficeLab),
    metadata: {
      office_lab_id: office.lab_metadata[0].id,
    },
  };
}
