import { zodResolver } from '@hookform/resolvers/zod';
import { UNEXPECTED_API_ERROR_MSG } from '@shared/constants/constants';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import { useToast } from '@shared/hooks/useToast';
import { useMakeFetchHappen } from '@shared/useMakeFetchHappen';
import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  description: z.string().min(1, { message: 'Issue Description is required' }),
  fileUpload: z.any().optional(),
  fileSource: z.any().optional(),
});

type FormDataShape = z.infer<typeof formSchema>;

type Props = {
  show: boolean;
  onClose: () => void;
};

export default function ReportIssue({ show, onClose }: Props) {
  const { data: populatedUser } = usePopulateUser();
  const { displayToast } = useToast();
  const { handler: makeFetchHappen } = useMakeFetchHappen();
  const { register, handleSubmit, setValue, formState, reset } = useForm<FormDataShape>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: '',
    },
    mode: 'all',
  });

  useEffect(() => {
    register('fileUpload');
  }, []);

  const reportIssueMutation = useMutation({
    mutationFn: async ({ description, fileUpload }: FormDataShape) => {
      const formData = new FormData();

      if (fileUpload) {
        formData.append('file', fileUpload);
      }
      formData.append('body', description);

      const res = await makeFetchHappen(`/internal/v1/report_issue/${populatedUser?.user_email}`, formData, {
        method: 'POST',
        setDefaultContentType: false,
      });
      return await res.body;
    },
    onError: () => {
      displayToast({
        variant: 'destructive',
        description: UNEXPECTED_API_ERROR_MSG,
      });
    },
    onSuccess: (data) => {
      if (data) {
        displayToast({
          variant: 'success',
          description: "Thanks for taking the time to improve SpecCheck! We'll follow up if we need additional FrameInformation.",
        });
        onClose();
      }
    },
  });

  function onSubmit(form: FormDataShape) {
    reportIssueMutation.mutate({
      description: form.description,
      fileSource: form.fileSource,
      fileUpload: form.fileUpload,
    });
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        reset();
      }}
    >
      <Modal.Header>
        <h5 className="modal-title">Report Issue</h5>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="modal-body">
          <p>We apologize for any inconvenience. Please add details about this issue.</p>
          <div className="mb-2">
            <label htmlFor="reportDescription" className="form-label">
              Issue Description
            </label>
            <textarea
              autoComplete="off"
              id="reportDescription"
              className={clsx('form-control form-message', {
                'is-invalid': Boolean(formState.errors.description),
              })}
              {...register('description')}
            />
            {formState.errors.description && <div className="invalid-feedback">{formState.errors.description.message}</div>}
          </div>
          <div>
            <label htmlFor="reportUpload" className="form-label">
              Upload
            </label>
            <input
              type="file"
              id="reportUpload"
              className="form-control"
              onChange={(e) => {
                if (e?.target?.files?.length === 0) {
                  return;
                }
                // Only support one file upload for now.
                const file = e?.target?.files?.[0];
                if (file) {
                  if (file.size > 5 * 1000 * 1000) {
                    alert('File size is too large; maximum file size is 5MB');
                    setValue('fileSource', '');
                  }
                } else {
                  setValue('fileSource', file);
                }
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={reportIssueMutation.isLoading}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={reportIssueMutation.isLoading || !formState.isValid}>
            {reportIssueMutation.isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              'Report Issue'
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
