export function getConfig() {
  const config = SC_CONFIG;

  return {
    ...config,
    auth: {
      ...config.auth,
      redirectUri: window.location.origin + config.auth.redirectUri,
    },
  };
}
