import usePopulateUser from '@shared/hooks/usePopulateUser';
import useLabStore from '@shared/state/lab-store';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { useShallow } from 'zustand/react/shallow';

import Shell from './shell/Shell';

export default function LabAppView() {
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams<{ lab_id: string }>();
  const navigate = useNavigate();
  const { setActiveLab } = useLabStore(useShallow((state) => ({ setActiveLab: state.setActiveLab })));
  const { data: populatedUser } = usePopulateUser();

  useEffect(() => {
    // NoOp until populatedUser is set
    if (!populatedUser) {
      return;
    }

    // Check to see if current params matches a lab that the user has access to
    const allLabs = populatedUser.labs;
    const lab = allLabs.find((lab) => params.lab_id === lab.lab_id);

    // Navigate user to default account route to kick off default lab setting
    if (!lab) {
      navigate('/account', {
        replace: true,
      });
      return;
    }

    // Always set the active lab. Because we can make changes and reload populatedUser within the app, we want to ensure that the active lab is also updated accordingly
    setActiveLab(lab);

    // If this is the first time this component is loading, do some vendor identification and 🚀
    if (isLoading) {
      if (!window.Cypress) {
        Userpilot.identify(populatedUser.auth0ID, {
          name: populatedUser.user_name,
          email: populatedUser.user_email,
          created_at: populatedUser.user_created_at,
          user_type: populatedUser.user_type,
          company: {
            id: lab.lab_id,
            name: lab.lab_name,
            lab_id: lab.lab_id,
            lab_name: lab.lab_name,
          },
        });
      }
      setIsLoading(false);
    }
  }, [isLoading, populatedUser, params.lab_id]);

  return isLoading ? null : (
    <div className="layout-dashboard-container">
      <Shell />
      <main id="main" className="tw-overflow-auto tw-mb-2 tw-flex tw-flex-col tw-min-h-full">
        <Outlet />
      </main>
    </div>
  );
}
