import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo } from 'react';
import { getConfig } from './utils/config';

const CONTENT_TYPE_MAP = {
  'application/json': 'application/json; charset=utf-8',
};

export type RequestData = Record<string, unknown> | FormData;
type RequestOptions = {
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT';
  additionalHeaders?: { [key: string]: string };
  includeUserIdInHeader?: boolean;
  setDefaultContentType?: boolean;
};

const config = getConfig();
const API_HOST = config.dev.serverUrl;

type Handler = <T>(path: string, data?: RequestData, options?: RequestOptions) => Promise<ResponseWrapper<T>>;

export function useMakeFetchHappen() {
  const { user, getAccessTokenSilently, isLoading, loginWithRedirect } = useAuth0();

  const handler: Handler = useCallback(
    (
      path: string,
      data: RequestData = {},
      options: RequestOptions = {
        additionalHeaders: {},
        includeUserIdInHeader: true,
        setDefaultContentType: true,
      }
    ) => {
      async function makeFetchHappen<T>() {
        // Set option defaults in case object is partially defined.
        if (options.includeUserIdInHeader === undefined) {
          options.includeUserIdInHeader = true;
        }

        if (options.setDefaultContentType === undefined) {
          options.setDefaultContentType = true;
        }

        let token: string;
        try {
          token = await getAccessTokenSilently();
        } catch (e) {
          // @ts-expect-error
          if (e?.error === 'login_required') {
            await loginWithRedirect({
              redirectUri: config.auth.redirectUri,
            });
          }
          return null as any;
        }

        options.method = options.method ?? 'GET';

        const additionalHeaders = {
          ...options.additionalHeaders,
        };

        if (options.includeUserIdInHeader && user?.sub) {
          additionalHeaders['User-Id'] = user?.sub;
        }

        const opts: Record<string, any> = {
          method: options.method,
          headers: {
            Authorization: `Bearer ${token}`,
            ...additionalHeaders,
          },
        };

        if (options.setDefaultContentType) {
          // set default content type
          opts.headers = {
            ...opts.headers,
            'Content-Type': CONTENT_TYPE_MAP['application/json'],
          };
        }

        if (options.method === 'POST' || options.method === 'DELETE' || options.method === 'PUT') {
          if (opts.headers['Content-Type'] === CONTENT_TYPE_MAP['application/json']) {
            opts.body = JSON.stringify({ ...data });
          } else {
            opts.body = data;
          }
        }

        try {
          const res = await fetch(`${API_HOST}/${path}`, opts);
          const x = await ResponseWrapper.create<T>(res);

          return x;
        } catch (err) {
          console.error(err);
          throw err;
        }
      }
      return makeFetchHappen();
    },
    [user?.sub, getAccessTokenSilently]
  );

  return useMemo(() => {
    return {
      handler,
      isLoading: isLoading,
    };
  }, [handler, isLoading]);
}

export class ResponseWrapper<T> {
  status: number;
  body: Promise<T>;

  constructor(response: Response) {
    this.status = response.status;
    this.body = response.json();
  }
  static async create<T>(response: Response) {
    if (response.ok) {
      return new ResponseWrapper<T>(response);
    } else {
      const bodyContent = await response.json();
      const errorMsg = bodyContent.error_msg || response.statusText;
      throw new ResponseError(errorMsg, response.status);
    }
  }
}

export type ResponseErrorType = InstanceType<typeof ResponseError>;

export class ResponseError extends Error {
  public override name: 'ResponseError';
  public status: number;

  constructor(message: string, status: number) {
    super(message || 'Response not ok');

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError);
    }
    this.name = 'ResponseError';
    this.status = status;
  }
}
