import { ILab } from '@core/models/lab.model';
import { IOffice } from '@core/models/office.model';
import { IUserMetadata } from '@shared/models/user-metadata.model';
import { useCallback } from 'react';

export default function useIntercomInjector() {
  const injectIntercom = useCallback((currentECP: IOffice, currentLabForEcp: ILab, userMetadata: IUserMetadata) => {
    if (currentLabForEcp.intercom_settings) {
      let intercomSettingsElement = document.createElement('script');
      intercomSettingsElement.id = 'ic-settings';
      intercomSettingsElement.innerHTML = `
    window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "${currentLabForEcp.intercom_settings.app_id}", 
    user_id: "${userMetadata.auth0ID}",
    email: "${userMetadata.user_email}",
    user_hash: "${currentLabForEcp.intercom_settings.hmac}",
    name: "${currentLabForEcp.account_number} - ${userMetadata.user_name}",
    company: {
    company_id: "${currentECP.id}",
    name: "${currentECP.name}"
    }
    };`;
      document.body.appendChild(intercomSettingsElement);

      let intercomInjectElement = document.createElement('script');
      intercomInjectElement.id = 'ic-execute';
      intercomInjectElement.innerHTML = `
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${currentLabForEcp.intercom_settings.app_id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
      document.body.appendChild(intercomInjectElement);
    }
  }, []);

  return injectIntercom;
}
