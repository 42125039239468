import { zodResolver } from '@hookform/resolvers/zod';
import { UNEXPECTED_API_ERROR_MSG } from '@shared/constants/constants';
import usePopulateUser, { USE_POPULATE_USER_CACHE_KEY } from '@shared/hooks/usePopulateUser';
import { useToast } from '@shared/hooks/useToast';
import Select from '@shared/react_components/Select';
import useOfficeLabStore from '@shared/state/office-lab-store';
import { ResponseError, useMakeFetchHappen } from '@shared/useMakeFetchHappen';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm, useWatch } from 'react-hook-form';
import invariant from 'tiny-invariant';
import { z } from 'zod';

const formSchema = z.object({
  lab_name: z.string().min(1, { message: 'Lab Name is required' }),
  account_number: z.string().min(1, { message: 'Account Number is required' }),
  invoice_number: z.string().min(1, { message: 'Invoice Number is required' }),
});

type FormDataShape = z.infer<typeof formSchema>;

type Props = {
  show: boolean;
  onClose: () => void;
};

export default function AddAdditonalLab({ show, onClose }: Props) {
  const queryClient = useQueryClient();
  const currentOfficeLab = useOfficeLabStore();
  const { data: populatedUser } = usePopulateUser();
  invariant(populatedUser, 'populatedUser should be set within AddAdditonalLab');
  const labOptions = [{ id: '', name: 'Select a lab' }, ...populatedUser.addable_labs.map((lab) => ({ id: lab, name: lab }))];
  const { displayToast } = useToast();
  const { handler: makeFetchHappen } = useMakeFetchHappen();
  const { register, control, clearErrors, trigger, handleSubmit, setValue, formState, reset } = useForm<FormDataShape>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      lab_name: '',
      account_number: '',
      invoice_number: '',
    },
    mode: 'all',
  });

  useEffect(() => {
    // Register the 'lab_name' field
    register('lab_name');
  }, [register]);

  const [labName] = useWatch({
    control,
    name: ['lab_name'],
  });

  const selectedLab = { id: labName, name: labName || 'Select a lab' };

  const addLabMutation = useMutation({
    mutationFn: async ({ lab_name, account_number, invoice_number }: FormDataShape) => {
      const res = await makeFetchHappen(
        `/internal/v1/ecps/${currentOfficeLab.office.id}/lab`,
        {
          lab_name: lab_name,
          account_number: account_number,
          invoice_number: invoice_number,
        },
        {
          method: 'POST',
        }
      );
      return await res.body;
    },
    onError: (err: ResponseError) => {
      switch (err.status) {
        case 422:
          displayToast({
            variant: 'destructive',
            description:
              'Uhh oh! We were unable to validate your account number. Please try again. If this issue persists, please reach out to your lab rep directly.',
            duration: 10000,
          });
          break;
        default:
          displayToast({
            variant: 'destructive',
            description: UNEXPECTED_API_ERROR_MSG,
            duration: 10000,
          });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(USE_POPULATE_USER_CACHE_KEY);
      displayToast({
        variant: 'success',
        description: 'Lab successfully added!',
        duration: 5000,
      });
      onClose();
    },
  });

  function onSubmit(form: FormDataShape) {
    addLabMutation.mutate({
      lab_name: form.lab_name,
      account_number: form.account_number,
      invoice_number: form.invoice_number,
    });
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        reset();
      }}
    >
      <Modal.Header>
        <h5 className="modal-title">Add Additional Lab</h5>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body className="modal-body">
          <div className="mb-2">
            <label htmlFor="labName" className="form-label">
              Lab Name<span className="text-primary">*</span>
            </label>
            <Select
              selected={selectedLab}
              options={labOptions}
              onSelect={(item) => {
                if (item.id === '') {
                  // We selected the default value
                  setValue('lab_name', '');
                  trigger('lab_name');
                  return;
                }
                clearErrors('lab_name');
                setValue('lab_name', item.id);
                trigger('lab_name');
              }}
              displayFn={(item) => item.name}
              listRowDisplayFn={(item) => {
                if (item.id === '') {
                  return <span className="text-muted">{item.name}</span>;
                }
                return item.name;
              }}
            />
            {formState.errors.lab_name && <div className="invalid-feedback">{formState.errors.lab_name.message}</div>}
          </div>
          <div className="mt-2">
            <label htmlFor="accountNumber" className="form-label">
              Account Number<span className="text-primary">*</span>
            </label>
            <input
              className={clsx('form-control', {
                'is-invalid': Boolean(formState.errors.account_number),
              })}
              id="accountNumber"
              autoComplete="off"
              {...register('account_number')}
            />
            {formState.errors.account_number && <div className="d-block invalid-feedback">{formState.errors.account_number.message}</div>}
          </div>
          <div className="mt-2">
            <label htmlFor="invoiceNumber" className="form-label">
              Invoice Number<span className="text-primary">*</span>
              <span className="fst-italic"> - must not be older than 30 days</span>
            </label>
            <input
              className={clsx('form-control', {
                'is-invalid': Boolean(formState.errors.invoice_number),
              })}
              id="invoiceNumber"
              autoComplete="off"
              {...register('invoice_number')}
            />
            {formState.errors.invoice_number && <div className="d-block invalid-feedback">{formState.errors.invoice_number.message}</div>}
            <p className="mt-2">
              <span className="fw-bold text-danger">Important:</span> For your security, Invoice Number is required. If you are a new
              account with the lab and you do not have a recent Invoice Number, please reach out to your lab directly to be granted access
              to SpecCheck.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            disabled={addLabMutation.isLoading}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={addLabMutation.isLoading || !formState.isValid}>
            {addLabMutation.isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              'Add Lab'
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
