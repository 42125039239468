import { cn } from '@shared/utils/styles';
import React from 'react';

interface Props {
  className?: string;
}

export default function Separator({ className }: Props) {
  return <div className={cn('tw-w-full tw-h-[1px] tw-bg-neutral-200', className)}></div>;
}
