import * as React from 'react';
import { MagnifyingGlassIcon } from './Icons';
import { cn } from '@shared/utils/styles';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

export const SearchBar = ({ className, ...props }: Props) => {
  return (
    <div className="tw-w-full tw-relative tw-max-w-[500px]">
      <div className="tw-absolute tw-top-1 tw-left-[0.75rem]">
        <MagnifyingGlassIcon />
      </div>
      <input id="ecpSearch" placeholder="Search" className={cn('form-control tw-pl-8 tw-h-9', className)} {...props} />
    </div>
  );
};
