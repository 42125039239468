import useOfficeLabStore from '@shared/state/office-lab-store';
import usePopulateUser from './usePopulateUser';

export default function useIntercom() {
  const { data: user } = usePopulateUser();
  const officeLab = useOfficeLabStore();

  return {
    isAvailable: user && user.user_type == 'ecpUser' && officeLab.lab.intercom_settings,
  };
}
