import React from 'react';
import { KeyboardEvent, useEffect } from 'react';

const keyMap: Record<string, string> = {
  metaKey: 'Command',
  ctrlKey: 'Ctrl',
  shiftKey: 'Shift',
};

const constructShortcutString = (
  activeKey: string,
  modifiers: {
    metaKey?: boolean;
    ctrlKey?: boolean;
    shiftKey?: boolean;
  }
) => {
  const modifierKeys = Object.keys(modifiers).filter((key) => modifiers[key as keyof typeof modifiers]);
  const modifierStrings = modifierKeys.map((key) => keyMap[key]);
  return [
    ...modifierStrings.sort((a, b) => {
      // Sort Shift before Command before Ctrl
      if (a === 'Shift') return -1;
      if (b === 'Shift') return 1;
      if (a === 'Command') return -1;
      if (b === 'Command') return 1;
      if (a === 'Ctrl') return -1;
      if (b === 'Ctrl') return 1;
      return 0;
    }),
    activeKey,
  ].join('-');
};

interface Props {
  keys: string[];
  onKeyPress: (key: string, event: KeyboardEvent, shortcutString: string) => void;
  handleFocusableElements?: boolean;
  disabled?: boolean;
  eventType?: 'keydown' | 'keyup' | 'keypress';
}

const Keyboard = ({ keys, onKeyPress, handleFocusableElements, disabled, eventType }: Props) => {
  useEffect(() => {
    const handleKeyboardEvent = (event: KeyboardEvent) => {
      if (disabled) return false;
      if (eventType && eventType !== event.type) return false;

      const isEligible = event.target === document.body || handleFocusableElements;

      if (!isEligible) {
        return false;
      }

      const matchedKey = keys.includes(event.key) ? event.key : null;

      if (matchedKey || keys.includes('all')) {
        onKeyPress(
          matchedKey || event.key,
          event,
          constructShortcutString(event.key, {
            metaKey: event.metaKey,
            ctrlKey: event.ctrlKey,
            shiftKey: event.shiftKey,
          })
        );
        return true;
      }

      return false;
    };

    // The callbacks here are not properly being narrowed into the
    // KeyboardEventHandler type. I have manually verified that the
    // types are correct, but TypeScript is not recognizing them as
    // such.

    // @ts-expect-error
    document.addEventListener('keydown', handleKeyboardEvent, false);
    // @ts-expect-error
    document.addEventListener('keyup', handleKeyboardEvent, false);
    // @ts-expect-error
    document.addEventListener('keypress', handleKeyboardEvent, false);

    return () => {
      // @ts-expect-error
      document.removeEventListener('keydown', handleKeyboardEvent, false);
      // @ts-expect-error
      document.removeEventListener('keyup', handleKeyboardEvent, false);
      // @ts-expect-error
      document.removeEventListener('keypress', handleKeyboardEvent, false);
    };
  }, [keys, onKeyPress, handleFocusableElements, disabled, eventType]);

  return <></>;
};

Keyboard.defaultProps = {
  handleFocusableElements: false,
  disabled: false,
  eventType: 'keydown',
};

export default Keyboard;
