import React from 'react';
import ResourceSwitcher from './ResourceSwitcher';
import AnimatedScreen from './AnimatedScreen';
import InitialScreen from './InitialScreen';
import ResourceListScreen from './ResourceListScreen';
import { AnimatePresence } from 'framer-motion';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import invariant from 'tiny-invariant';
import useOfficeLabStore from '@shared/state/office-lab-store';
import { mapOfficeLabToResource, mapOfficeToResource } from './utils';

export default function ECPResourceSwitcher() {
  const { data: user } = usePopulateUser();
  invariant(user, 'User cannot be null in lab dropdown');

  const { office: currentOffice, lab: currentOfficeLab } = useOfficeLabStore();

  const currentLabResource = mapOfficeLabToResource(currentOfficeLab);
  const currentOfficeResource = mapOfficeToResource(currentOffice, currentOfficeLab);

  const labs = currentOffice.lab_metadata.map(mapOfficeLabToResource).filter((lab) => lab.id !== currentOfficeLab.id);
  const offices = user.office_metadata
    .filter((office) => office.id !== currentOffice.id)
    .map((office) => mapOfficeToResource(office, currentOfficeLab));

  return (
    <ResourceSwitcher currentResource={currentOfficeResource}>
      {({ screen, setScreen, setOpen }) => (
        <AnimatePresence initial={false} custom={screen} mode="wait">
          {screen === 'initial' && (
            <AnimatedScreen key="initial" screen={screen} type="initial">
              <InitialScreen
                currentResource={currentOfficeResource}
                setScreen={setScreen}
                showLabSwitcher={labs.length > 0}
                showOfficeSwitcher={offices.length > 0}
              />
            </AnimatedScreen>
          )}

          {screen === 'labList' && (
            <AnimatedScreen key="labList" screen={screen} type="resourceList">
              <ResourceListScreen
                currentResource={currentLabResource}
                resourceList={[currentLabResource, ...labs]}
                setOpen={setOpen}
                setScreen={setScreen}
              />
            </AnimatedScreen>
          )}

          {screen === 'officeList' && (
            <AnimatedScreen key="officeList" screen={screen} type="resourceList">
              <ResourceListScreen
                currentResource={currentOfficeResource}
                resourceList={[currentOfficeResource, ...offices]}
                setOpen={setOpen}
                setScreen={setScreen}
              />
            </AnimatedScreen>
          )}
        </AnimatePresence>
      )}
    </ResourceSwitcher>
  );
}
