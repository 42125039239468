import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';

import ChevronRightIcon from '@assets/icons/ChevronRight-24-Black.svg';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
}

export default function NextButton({ onClick, children, ...props }: PropsWithChildren<Props>) {
  return (
    <button
      className="tw-appearance-none tw-shadow-none tw-border-none tw-h-10 tw-w-full tw-rounded-lg tw-bg-transparent tw-transition hover:tw-bg-primary-50 focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-primary-200 tw-px-2 tw-flex tw-items-center tw-justify-between tw-flex-row"
      onClick={onClick}
      tabIndex={0}
      {...props}
    >
      <span className="tw-text-sm">{children}</span>
      <ChevronRightIcon />
    </button>
  );
}
