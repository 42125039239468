import { EVENTS } from '@shared/constants/events';
import usePopulateUser from '@shared/hooks/usePopulateUser';
import ErrorScreen from '@shared/react_components/ErrorScreen';
import Loading from '@shared/react_components/Loading';
import useOfficeLabStore from '@shared/state/office-lab-store';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Account() {
  const { isSuccess, data, error } = usePopulateUser();
  const navigate = useNavigate();
  const officeLab = useOfficeLabStore();

  useEffect(() => {
    if (isSuccess && data) {
      if (data.user_type === 'labUser') {
        let labId = data.labs[0].lab_id;
        if (officeLab.lab.id) {
          labId = officeLab.lab.id;
        }
        navigate(`/lab/${labId}`);
      } else if (data.user_type === 'ecpUser') {
        const defaultOffice =
          data.office_metadata.find((office) => office.id === data.user_preferences.default_ecp_id) || data.office_metadata[0];
        let labId = defaultOffice.lab_metadata.find((lab) => lab.is_default)?.id || defaultOffice.lab_metadata[0].id!;
        let ecpId = defaultOffice.id!;
        if (officeLab.office.id && officeLab.lab.id) {
          ecpId = officeLab.office.id;
          labId = officeLab.lab.id;
        }
        navigate(`/ecp/${ecpId}/lab/${labId}`);
      }
    }
  }, [isSuccess, navigate, officeLab, data]);

  let content;
  if (error) {
    content = <ErrorScreen title="Authentication Failed" source="Account(Call error)" event={EVENTS.AUTHNZ_ERROR} />;
  } else if (isSuccess && data && data.user_type == 'unknown') {
    content = (
      <ErrorScreen
        title="Authorization Failed"
        description={
          <p>
            Oops! It looks like we can&apos;t find a SpecCheck account associated with your user profile. If you believe you&apos;re
            receiving this message in error, please contact: <a href="mailto:support@speccheckrx.com">support@speccheckrx.com</a>.
          </p>
        }
        source="Account(No accounts found)"
        event={EVENTS.AUTHNZ_ERROR}
      />
    );
  } else {
    content = <Loading />;
  }

  return <div className="tw-flex tw-grow tw-items-center tw-justify-center tw-h-full pt-3 bg-accent">{content}</div>;
}
