import React, { Dispatch, SetStateAction } from 'react';
import { ResourceIcon, ResourceMeta, ResourceName } from './components';
import { Resource, Screen } from './types';
import NextButton from './NextButton';
import Address from '@shared/react_components/Address';
import Keyboard from '@shared/react_components/Keyboard';

interface Props {
  currentResource: Resource;
  setScreen: Dispatch<SetStateAction<Screen>>;
  showLabSwitcher: boolean;
  showOfficeSwitcher: boolean;
}

export default function InitialScreen({ currentResource, setScreen, showLabSwitcher, showOfficeSwitcher }: Props) {
  return (
    <>
      <div className="tw-w-full tw-p-2 tw-flex tw-items-center tw-justify-start tw-flex-row tw-gap-2">
        <ResourceIcon resourceType={currentResource.type} className="tw-bg-primary-50 tw-text-primary-500" />
        <div className="tw-w-full tw-flex tw-items-start tw-justify-center tw-flex-col tw-gap-1">
          <ResourceName>{currentResource.name}</ResourceName>
          <div className="tw-max-w-[180px]">
            <ResourceMeta>
              <Address address={currentResource.address} />
            </ResourceMeta>
          </div>
          {currentResource.labInformation && (
            <div className="-tw-mt-1">
              <ResourceMeta>{currentResource.labInformation}</ResourceMeta>
            </div>
          )}
        </div>
      </div>

      {(showLabSwitcher || showOfficeSwitcher) && <div className="tw-w-full tw-h-[1px] tw-bg-neutral-200 tw-my-2"></div>}

      <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
        {showLabSwitcher && <NextButton onClick={() => setScreen('labList')}>Switch Lab</NextButton>}
        {showOfficeSwitcher && <NextButton onClick={() => setScreen('officeList')}>Switch Office</NextButton>}
      </div>
    </>
  );
}
