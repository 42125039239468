import clsx from 'clsx';
import React from 'react';

type Props = {
  title?: string;
  cover?: boolean;
};
export default function Loading({ title = 'Loading...', cover }: Props) {
  return (
    <div
      className={clsx(
        'tw-flex tw-items-center tw-justify-center tw-flex-col',
        cover && 'tw-absolute tw-inset-0 tw-z-50 tw-w-full tw-h-full tw-bg-primary-translucent'
      )}
    >
      <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status"></div>
      <span className="text-primary">{title}</span>
    </div>
  );
}
